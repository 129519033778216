import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    initialFormData: {},
    formData: {},
    validate: {
      getValidationState: () => {
      },
    },
  }),
  computed: {
    isNew() {
      return !(this.id > 0)
    },
    pageWidgetTitle() {
      return this.isNew ? 'New Record' : 'Update Record'
    },
  },
  methods: {
    initialForm() {
      this.initialFormData = JSON.parse(JSON.stringify(this.formData))
    },
    initalValidate() {
      const { refFormObserver, getValidationState, resetForm } = formValidation(
        this.resetForm,
      )
      const onSubmit = this.onSubmit
      return {
        onSubmit,
        refFormObserver,
        getValidationState,
        resetForm,
      }
    },
    resetForm() {
      this.formData = JSON.parse(JSON.stringify(this.initialFormData))
    },
    onSubmit(event) { // eslint-disable-line no-unused-vars
    },
    onReset(reset) { // eslint-disable-line no-unused-vars
      this.resetForm()
      reset()
    },
  },
}
