<template>
  <b-sidebar
    id="create-or-edit-sidebar"
    refs="createOrEditSidebar"
    bg-variant="white"
    shadow
    backdrop
    right
    width="640px"
    :title="pageWidgetTitle"
    no-close-on-backdrop
    aria-modal="true"
    @hidden="onSidebarHidden"
    @shown="onSidebarShow"
  >
    <template #footer="{ hide }">
      <div class="d-flex justify-content-between p-1 border-top">
        <b-button size="sm" variant="success" @click="onSubmit">
          Save
        </b-button>
        <b-button variant="dark" class="mr-1" @click="onCancel(hide)">
            Cancel
        </b-button>
      </div>
    </template>

    <template #header="{ hide }">
      <div class="d-flex justify-content-between p-1 w-100">
        <div>
          <strong>{{ pageWidgetTitle }} </strong>
            <span v-if="!isNew"> - #{{ id }}</span>
        </div>
        <div><b-btn-close @click="hide" /></div>
      </div>
    </template>

    <template #default>
      <div v-if="isBusy" class="text-center m-1 pt-3 pb-0">
        <div class="spinner-border" role="status" />
        <p class="pt-2">
          <strong>Working on it...</strong><br>
          <small>This shouldn't take long</small>
        </p>
      </div>
      <div v-if="!isBusy" class="p-1">
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit, reset }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="mb-5"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="onReset(reset)"
          >
          <div class="row">
            <div class="col-6">
            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Account No"
              rules="required"
            >
            <b-form-group label-size="sm" label="Account or Suite No">
              <b-input-group size="sm">
                <b-form-input
                  v-model="formData.accountNo"
                  autofocus
                  type="number"
                  :state="validate.getValidationState(validationContext)"
                  trim
                  placeholder="Account or Suite No"
                />
              <b-input-group-append>
                    <b-button size="sm" text="Button" variant="success" @click="onChangeAccountName(formData.accountNo)">
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
              </b-input-group>
            </b-form-group>
            </validation-provider>
            </div>
            <div class="col-6">
            <!-- companyName -->
            <validation-provider
              #default="validationContext"
              name="companyName"
              rules="required"
            >
              <b-form-group label="Company Name" label-size="sm">
                <b-form-input
                  v-model="formData.companyName"
                  autofocus
                  readonly
                  size="sm"
                  type="text"
                  :state="validate.getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </div>
          </div>
            <!-- storeName -->
            <validation-provider
              #default="validationContext"
              name="storeName"
              rules="required"
            >
              <b-form-group label="Store Name" label-size="sm">
            <b-form-select
                  v-model="formData.storeId"
                  :options="formData.stores"
                  autofocus
                  size="sm"
                  :state="validate.getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- title -->
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group label="Title" label-size="sm">
                <b-form-input
                  v-model="formData.title"
                  size="sm"
                  autofocus
                  type="text"
                  :state="validate.getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- productId -->
            <validation-provider
              #default="validationContext"
              name="productId"
              rules="required"
            >
              <b-form-group label="Product" label-size="sm">
                <b-form-select
                  v-model="formData.productId"
                  :options="formData.products"
                  autofocus
                  size="sm"
                  :state="validate.getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="row">
              <div class="col-6">
                <!-- fnsku -->
                <validation-provider
                  #default="validationContext"
                  name="fnsku"
                  rules="required"
                >
                  <b-form-group label="FNSKU" label-size="sm">
                    <b-form-input
                      v-model="formData.fnsku"
                      autofocus
                      type="text"
                      readonly
                      size="sm"
                      :state="validate.getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="col-6">
                <!-- asin -->
                <validation-provider
                  #default="validationContext"
                  name="Asin"
                  rules="required"
                >
                  <b-form-group label="ASIN" label-size="sm">
                    <b-form-input
                      v-model="formData.asin"
                      autofocus
                      readonly
                      type="text"
                      size="sm"
                      :state="validate.getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <!-- msku -->
                <validation-provider
                  #default="validationContext"
                  name="msku"
                  rules="required"
                >
                  <b-form-group label="MSKU" label-size="sm">
                    <b-form-input
                      v-model="formData.msku"
                      autofocus
                      readonly
                      type="text"
                      size="sm"
                      :state="validate.getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="col-6">
                <!-- upc -->
                <validation-provider
                  #default="validationContext"
                  name="upc"
                  rules="required"
                >
                  <b-form-group label="UPC" label-size="sm">
                    <b-form-input
                      v-model="formData.upc"
                      autofocus
                      type="text"
                      readonly
                      size="sm"
                      :state="validate.getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>

            <!-- clientShipmentItemId -->
            <validation-provider
              #default="validationContext"
              name="ClientShipmentItemId"
              rules="required|integer"
            >
              <b-form-group label="Client Shipment Item" label-size="sm">
                <b-form-input
                  v-model="formData.clientShipmentItemId"
                  autofocus
                  type="number"
                  size="sm"
                  :state="validate.getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- prepItemCostId -->
            <validation-provider
              #default="validationContext"
              name="companyId"
              rules="required|integer"
            >
              <b-form-group label="Prep Item Cost" label-size="sm">
                <b-form-input
                  v-model="formData.prepItemCostId"
                  size="sm"
                  autofocus
                  type="number"
                  :state="validate.getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- prepTime -->
            <validation-provider
              #default="validationContext"
              name="prepTime"
              rules="required"
            >
              <b-form-group>
                <label class="label label-sm" for="prep-time">Prep Time</label>
                <b-form-datepicker
                  id="prep-time"
                  v-model="formData.prepTime"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', }"
                  :state="validate.getValidationState(validationContext)"
                  locale="en"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="row">
              <div class="col-4">
            <!-- quantity -->
            <validation-provider
              #default="validationContext"
              name="quantity"
              rules="required|integer"
            >
              <b-form-group label="Quantity" label-size="sm">
                <b-form-input
                  v-model="formData.quantity"
                  autofocus
                  type="number"
                  :state="validate.getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
              </div>
              <div class="col-4">
            <!-- receivedCount -->
            <validation-provider
              #default="validationContext"
              name="receivedCount"
              rules="required|integer"
            >
              <b-form-group label="Received Count" label-size="sm">
                <b-form-input
                  v-model="formData.receivedCount"
                  autofocus
                  type="number"
                  :state="validate.getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
              </div>
              <div class="col-4">
                            <!-- sentCount -->
            <validation-provider
              #default="validationContext"
              name="sentCount"
              rules="required|integer"
            >
              <b-form-group label="Sent Count" label-size="sm">
                <b-form-input
                  v-model="formData.sentCount"
                  autofocus
                  type="number"
                  size="sm"
                  :state="validate.getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { Form } from '@robustshell/mixins/index'
import { Notify } from '@robustshell/utils/index'
import inventoryService from '@/http/requests/inventory/inventoryService'
import companyService from '@/http/requests/company/companyService'

export default {
  components: {
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [Form],
  data: () => ({
    required,
    isBusy: false,
    formData: {
      clientShipmentItemId: 0,
      companyId: 0,
      companyName: '',
      prepItemCostId: 0,
      prepTime: '',
      productId: null,
      asin: '',
      fnsku: '',
      msku: '',
      upc: '',
      products: [],
      quantity: 0,
      receivedCount: 0,
      sentCount: 0,
      storeName: '',
      storeId: null,
      stores: [],
      title: '',
    },
    productData: [],
  }),
  computed: {
    productId() {
      return this.formData.productId
    },
  },
  watch: {
    id(newVal, val) {
      if (!this.isNew && newVal !== val) {
        const self = this
        this.isBusy = true
        const data = inventoryService.fetchById(newVal)
        data.then(result => {
          const item = result.data
          self.formData.asin = item.asin
          self.formData.clientShipmentItemId = item.clientShipmentItemId
          self.formData.companyId = item.companyId
          self.formData.companyName = item.companyName
          self.formData.fnsku = item.fnsku
          self.formData.msku = item.msku
          self.formData.prepItemCostId = item.prepItemCostId
          self.formData.prepTime = item.prepTime
          self.formData.productId = item.productId.id
          self.formData.quantity = item.quantity
          self.formData.receivedCount = item.receivedCount
          self.formData.sentCount = item.sentCount
          self.formData.storeName = item.storeName
          self.formData.title = item.title
          self.formData.upc = item.upc
          self.isBusy = false
        })
      }
    },
    productId(newVal) {
      if (newVal) {
        const selectedProduct = this.productData.filter(
          item => item.id === newVal,
        )
        this.formData.asin = selectedProduct[0].asin
        this.formData.upc = selectedProduct[0].upc
        this.formData.msku = selectedProduct[0].msku
        this.formData.fnsku = selectedProduct[0].fnsku
      }
    },
  },
  mounted() {
    this.initialForm()
    this.validate = this.initalValidate()
    this.onFetchProducts()
  },
  methods: {
    onFetchProducts() {
      // const items = productService.fetchProducts()
      // items.then(result => {
      //   this.productData = result.data
      //   result.data.forEach(element => {
      //     this.formData.products.push({
      //       value: element.id,
      //       text: element.title,
      //     })
      //   })
      // })
    },
    onChangeAccountName(accountNo) {
      const companyInfo = companyService.fetchCompanyAndStoresByAccountNo(accountNo)
      companyInfo.then(result => {
        this.formData.companyId = result.data.id
        this.formData.companyName = result.data.name
        result.data.stores.forEach(element => {
          this.formData.stores.push({
            value: element.id,
            text: element.name,
          })
        })
      })
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.$root.$emit('bv::toggle::collapse', 'create-or-edit-sidebar')
          inventoryService.add(this.formData)
        } else {
          Notify.warning('Please check the fields.')
        }
      })
    },
    onCancel(hide) {
      hide()
      this.onReset(this.$refs.refFormObserver.reset)
    },
    onSidebarHidden() {
      this.onReset(this.$refs.refFormObserver.reset)
    },
    onSidebarShow() {
      this.onFetchProducts()
    },
  },
}
</script>
